import {
  Text,
  VStack,HStack,
  Button,
  Input,
  Spinner,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  Checkbox,
  Spacer,
  Select,
  FormHelperText,
} from '@chakra-ui/react';
import {useSystemsContext} from '../../contexts/IpfsOrbitDb/systems';
import BuilderPage from './forms/formBuilder';
import Output from './forms/output';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import CopyableText from '../commons/copyableText';
// import {useWeb3Context} from '../../contexts/Web3Context';
// import {addDatabase} from '../../libs/databaseLib';
import DatabaseImport from './databaseImport';
const Web3 = require('web3');

function CreateForm() {
  const [formData, setFormData] = useState([]);
  const  [ipfsNode, orbit, loading, programs] = useSystemsContext();
  const [creation, setCreation] = useState(false);
  // const [toggleBuilder ,setToggleBuilder] = useState(true);
  const [nameForm, setNameForm ] = useState();
  const [description, setDescription ] = useState();
  const [permissions, setPermissions] = useState('public');
  const [keyDefined, setKeyDefined] = useState('orbitdb');
  const [formCreated, setFormCreated] = useState();
  const [type, setType] = useState('keyvalue');
  const [permissionsType, setPermissionsType] = useState();
  const [contractAddress, setContractAddress] = useState();
  const history = useHistory();

  const web3Provider = new Web3(window.ethereum);

  const dagPreparation = async (data) =>{
  // in put {pin:true} //test this!!
    let cid = await ipfsNode.dag.put(data);
    return cid;
  }


  async function createDatabase(){
      let accessController
      let option = permissionsType?permissionsType:permissions
      let options;
      let subName = nameForm.concat('_entries')
      switch (option) {
        case 'public':
          accessController = { write: ['*'] }
          break
        case 'daoHaus':
          options={ accessController:{ipfs: ipfsNode, web3: web3Provider, contractAddress:contractAddress
            ,type:'daohausmember'}} //,defaultAccount: [orbit.identity.id] //
          break
        case 'web3':
          accessController = {type:'wallet',defaultAccount: [orbit.identity.id]} //careful here
          break
        case 'orbitdb':
          accessController = {type:'orbitdb', write: [orbit.identity.id]}
          break
        default:
          accessController = { write: [orbit.identity.id] }
          break
      }
      let db;
      if(options){
        console.log('creating db with options:', options)
        db = await orbit.create(subName, type, options)
      }else{
        console.log('creating db with access:', accessController)
        db = await orbit.create(subName, type, accessController)
      }
      console.log('created',db)
      // programs.add({
      //   subName,
      //   type,
      //   address: db.address.toString(),
      //   added: Date.now()
      // })

      return db.address.toString();
    }

  async function newForm(){
    if(window.confirm('Do you want to create this form?')){
      let responsesDbId = await createDatabase();
      // let formDataJson = JSON.stringify(formData);// parse:error
      // const formDataCid = formData //await dagPreparation()???
      if(responsesDbId && formData){
        let newFormObj = {name:nameForm,
                          keyDefined:keyDefined,
                          description:description,
                          responses:responsesDbId,
                          formData:formData,
                          created:Date.now()}
        let newFormCid = await dagPreparation(newFormObj,{pin:true}) // i should pin every ipfs + orbitdb created! {pin:true}
// then gets added to the local DB (a feed in the browser's storage)
        programs.add({
          name:nameForm,
          type,
          dbAddress:newFormCid.toString(),
          description: description,
          responses: responsesDbId,
          added: Date.now()
        })

        console.log(newFormCid.toString())
        setFormCreated(newFormCid.toString())
        window.alert('New form created!')
        history.push('/form/'+newFormCid.toString())

        return newFormCid.toString();
        // Add to DB root?
      }else{
        return 'Error'
      }
    }else{
      console.log('rejected!')
      return
    }
  }

  return (
      <VStack w='100%'>
        <Text fontSize='md'>Information about the database</Text>
          {!creation?
          <form onSubmit={()=> {
            if(document.getElementById('daoAddress')){
              let contract = document.getElementById('daoAddress').value
              setContractAddress(contract)
            }
            setCreation(!creation)
          }}>
          <VStack w='100%'>
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input type="string" onChange={e=>setNameForm(e.target.value)}/>
              <FormHelperText>Let's start with a name for your form</FormHelperText>
            </FormControl>

            <FormControl id="description">
              <FormLabel>Description</FormLabel>
              <Input type="string" onChange={e=>setDescription(e.target.value)}/>
              <FormHelperText>Give some extra data for your public</FormHelperText>
            </FormControl>

            <FormControl id='type'>
              <FormLabel>Type</FormLabel>
              <Select id="type" defaultValue='keyvalue' onChange={(e)=>setType(e.target.value)}>
                <option value="eventlog">EventLog</option>
                <option value="eventlog">Feed</option>
                <option value="keyvalue">Key:Value</option>
                <option value="docstore">Docstore</option>
                <option value="counter">Counter</option>
                </Select>
            </FormControl>

            <FormControl id='keyDefined' isRequired>
              <FormLabel>Key</FormLabel>
              <Select id="keyDefined" defaultValue='orbitdb' onChange={(e)=>setKeyDefined(e.target.value)}>
                <option value="web3">web3 account</option>
                <option value="orbitdb">Orbit db Id</option>
                <option value="free">key value (free)</option>
              </Select>
            </FormControl>

            <HStack>
              <FormControl id="signable">
                <HStack>
                  <FormLabel>Signable</FormLabel>
                  <Checkbox isDisabled/>
                </HStack>
              </FormControl>

              <FormControl id="humans">
                <HStack>
                  <FormLabel>Only humans</FormLabel>
                  <Checkbox isDisabled/>
                </HStack>
              </FormControl>
              <FormControl id="oracle">
                <HStack>
                  <FormLabel>Oracle</FormLabel>
                  <Checkbox isDisabled/>
                </HStack>
              </FormControl>
            </HStack>

            <FormControl  isRequired>
              <FormLabel >Access for your form data</FormLabel>
              <RadioGroup value={permissions} onChange={(value)=>setPermissions(value)}>
                <HStack spacing="24px">
                  <Radio value="public">Public</Radio>
                  <Radio value="only">Only me</Radio>
                  <Radio value="access-control">Access Control</Radio>
                  <Radio isDisabled value="encrypted">Encrypted</Radio>
                  <Radio isDisabled value="whitelisted">Whitelisted</Radio>
                </HStack>
              </RadioGroup>
              {permissions === 'access-control'?
                <VStack>
                  {/*
                    the selection of access control should incur in what the key is for the db?
                    (how do i select that key?)

                    */ }
                  <Select id="permissions" defaultValue='orbitdb' onChange={(e)=>setPermissionsType(e.target.value)}>
                    <option value="orbitdb" >Orbit DB identity</option>
                    <option value="daoHaus" >DaoHaus member</option>
                    <option value="web3" >web3 identity</option>
                    <option disabled value="token" >token holders</option>
                  </Select>
                  {permissionsType === 'daoHaus'?
                    <Input id='daoAddress' placeholder='contract address'></Input>
                  :null}
                </VStack>
              :null}
              <FormHelperText>We are working on expanding your choices!</FormHelperText>
            </FormControl>
            <Button type='submit'>Create!</Button>
          </VStack>
          </form>
          :
          <HStack w='100%'>
            <VStack>

            <BuilderPage
            {...{
              formData,
              setFormData
            }}
            />

            {formCreated?
              <CopyableText text={formCreated} />
            :null}

            </VStack>
            <Spacer />
            {formData?
              <Output
              formData={formData}
              isCreation={true}
              formName={nameForm}
              formDescription={description}
              />
              :null}

          </HStack>
          }
          <Button isDisabled={!formData.length} onClick={()=>newForm()}>Spread!</Button> {/*does not disable correctly*/}
          <DatabaseImport />

          {loading?
            <VStack>
              <Text fontSize='sm'>Wait until connection is established with IPFS and OrbitDB</Text>
              <Spinner />
            </VStack>
          :null}
      </VStack>
  );
}

export default CreateForm;
