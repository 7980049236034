const AccessController = require('orbit-db-access-controllers/src/access-controller-interface')
const Web3 = require('web3');

// const isValidEthAddress = require('./utils/is-valid-eth-address')
const io = require('orbit-db-io')
const abi = [{"type":"function",
"stateMutability":"view",
"payable":false,
"outputs":[{"type":"address","name":"delegateKey"},
{"type":"uint256","name":"shares"},
{"type":"uint256","name":"loot"},
{"type":"bool","name":"exists"},
{"type":"uint256","name":"highestIndexYesVote"},
{"type":"uint256","name":"jailed"}],
"name":"members",
"inputs":[{"type":"address","name":""}],
"constant":true}]
const web3Provider = new Web3(window.ethereum);
const daoAddress = '0x10e31c10fb4912bc408ce6c585074bd8693f2158'

export default class DaoHausController extends AccessController {
    constructor (ipfs, web3Provider, defaultAccount) {
      super()
      this._ipfs = ipfs
      this.web3 = web3Provider
      this.abi = abi
      this.contractAddress = daoAddress // this should be in the constructor
      this.defaultAccount = defaultAccount
    }
    static get type () { return 'daohausmember' } // Return the type for this controller

    get address () {
      return this.contractAddress
    }

    async load (address) {
      console.log('loading', this)
      this.contract = new web3Provider.eth.Contract(this.abi, this.contractAddress)
      // https://github.com/orbitdb/orbit-db-access-controllers/blob/main/src/contract-access-controller.js
    }

    async canAppend(entry, signature) {
      // console.log('entry approved found:',entry)
      if(!entry.payload.key){
        console.log('Error, no account in entry')
        return false
      }
      // console.log('contract: ',this.contract)
      const memberRequest = await this.contract.methods.members(entry.payload.key).call()
      // console.log('memberRequest',memberRequest)
      if(memberRequest[3]){
        // console.log('passed!')
        return true
      }else{
        console.log('error in call to the contract')
        return false
      }
      }

    async grant (access, identity) {} // Logic for granting access to identity


    async save () {
      let cid
      console.log('saving',this)
      try {
        cid = await io.write(this._ipfs, 'dag-cbor', {
          contractAddress: this.address,
          abi: JSON.stringify(this.abi, null, 2)
        })
        console.log('saved',cid)
      } catch (e) {
        console.log('ContractAccessController.save ERROR:', e)
      }
      // return the manifest data
      return { address: cid }
    }

    static async create (orbitdb, options) {
      if (!options.contractAddress && !options.address) {
        throw new Error("No 'contractAddress' given in options")
      }
      // if (!options.web3) {
      //   throw new Error("No 'web3' given in options")
      // }
      // if (!options.defaultAccount) {
      //   console.warn('WARNING: no defaultAccount set')
      // }

      return new DaoHausController(
        orbitdb._ipfs,
        options.web3,
        options.contractAddress,
        options.defaultAccount
      )
    }

}

// receive a dao contract,
// check signature in entry
// validate address in contract
// perform entry

// access control:
//P2P
//---
// Owner user grants to user

// Add web3 context, get user address and send it in each entry
// Later that should be a signature that will be handled (and verifyied by the access control)
// access control should look at the contract members and only allow those to make entries!

// Contract based
// ---
// User can write if address in contract? => DAO!
// Raid Guild cohort season 1 DAO : 0x10E31C10FB4912BC408Ce6C585074bd8693F2158
// method: members(addres) >
// delegateKey(address) shares(uint256) loot(uint256) exists(bool) highestIndexYesVote(uint256) jailed(uint256)
// [
// (address) : 0x08b3931b2ae83113c711c92e1bb87989f1fab004
// (uint256) : 10
// (uint256) : 0
// (bool) : true
// (uint256) : 0
// (uint256) : 0
// ]
