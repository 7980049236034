import * as React from 'react';
import IPFS from 'ipfs'
import Config from './config'
import OrbitDB from 'orbit-db'
import DaoHausController from '../../libs/accessControl/access_test';
let AccessControllers = require('orbit-db-access-controllers')

AccessControllers.addAccessController({ AccessController: DaoHausController })
// instances
let orbitdb
let ipfs
let myDdb

const SystemsContext = React.createContext();
export const useSystemsContext = () => React.useContext(SystemsContext);

export const SystemsProvider = ({children}) => {
  const [ipfsNode, setIpfsNode] = React.useState();
  const [orbit, setOrbit] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [entries, setEntries] = React.useState(); //entries of local ddb storage (owned db)

  const getAllDatabases = async (orbitdb) => {
      if (!myDdb) {
        // Load programs database
        myDdb = await orbitdb.feed('browser.ddb', {
          accessController: { write: [orbitdb.identity.id] },
          create: true
        })
        await myDdb.load()
      }
      let entries
      if(myDdb){
        entries = await myDdb.iterator({ limit: 100 }).collect().reverse()
      }else{
        entries = []
      }
      setEntries(entries)
    }

  const initOrbitDB = async (ipfs) => {
    orbitdb = await OrbitDB.createInstance(ipfs, {repo:'./orbitDB', AccessControllers: AccessControllers})
    // console.log('OrbitDB connected!',orbitdb)
    setOrbit(orbitdb)
    if(orbitdb){
      getAllDatabases(orbitdb)
    }else{
      console.log('error on orbit db instance generation')
    }
    setLoading(false)
    return orbitdb
  }

  React.useEffect(() => {
    const initIPFS = async () => {
        ipfs = await IPFS.create(Config.ipfs)
        // console.log('IPFS connected!',ipfs)
        setIpfsNode(ipfs)
        return ipfs;
      }

    setLoading(true) //
    initIPFS().then(node=>{initOrbitDB(node)})

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <SystemsContext.Provider value={[ipfsNode, orbit, loading, myDdb, entries]}>{children}</SystemsContext.Provider>
}
