import React from 'react'
import { Spinner, HStack, VStack, Box, Text,  Button } from "@chakra-ui/react"
import { CheckCircleIcon} from '@chakra-ui/icons'
import {useWeb3Context} from '../contexts/Web3Context';
import {useSystemsContext} from '../contexts/IpfsOrbitDb/systems';

function Systems () {
  const { account, providerChainId,  loading, connectWeb3} = useWeb3Context();
  const  [, orbit,loadingIPFS , ,] = useSystemsContext();

  // const fetchDB = async (address, type) => {
  //   const db = ''//await getDB(address)
  //   if (db) {
  //     let entries
  //     if (db.type === 'eventlog' || db.type === 'feed')
  //       entries = await db.iterator({ limit: 5 }).collect().reverse()
  //     else if (db.type === 'counter')
  //       entries = [{ payload: { value: db.value } }]
  //     else if (db.type === 'keyvalue')
  //       entries = Object.keys(db.all).map(e => ({ payload: { value: {key: e, value: db.get(e)} } }))
  //     else if (db.type === 'docstore')
  //       entries = db.query(e => e !== null, {fullOp: true}).reverse()
  //     else
  //       entries = [{ payload: { value: "TODO" } }]
  //   }else{
  //     console.log(address, ' couldnt be found')
  //   }
  // }

  // async function initDatabases(){
  //   // await fetchDB('/orbitdb/zdpuB2TjWHFxPnxng4EUYX3B6s67EjcfXGf2J6uFZE7PbazCF/ipfsObject', 'ipfsObject')
  //   }

  const shortedId = (data)=>{return data.slice(0,4)+'..'+data.slice(-4,)}

  const SystemElement = (props)=> (
      <HStack fontSize='sm' spacing={1} fontWeight='semibold'>
        <Text>{props.name}</Text>{''}
        {props.isLoading?
          <Spinner />
          :
          <CheckCircleIcon />
        }
        {props.data?
          <Text>
          {props.data.length > 20 ? shortedId(props.data) :props.data}
          </Text>
        :null}
      </HStack>
  )

  return (
    <Box  border='1px solid lightgray'>
      <VStack  alignItems='left'>
          <SystemElement
            name = 'IPFS'
            isLoading = {loadingIPFS}
            />
          <SystemElement
            name = 'OrbitDB'
            isLoading = {loadingIPFS}
            data = {orbit?orbit.identity._id:'loading'}
            />
{/*
          <SystemElement
            name = 'User'
            isLoading = {!appState.user}
            data = {appState.user}
            />
*/}
          {!account?
            <Button onClick={()=>connectWeb3()}>Connect wallet</Button>
            :
            <SystemElement
            name = 'Web3'
            isLoading = {loading}
            data = {providerChainId !== 100? 'Connect to xdai' : account}
            />
          }

      </VStack>
    </Box>
  )
}

export default Systems
