import DaoHausController from './accessControl/access_test';
const CID = require('cids')
let AccessControllers = require('orbit-db-access-controllers')
AccessControllers.addAccessController({ AccessController: DaoHausController })

export const getAllDatabases = async (programs, orbitdb) => {
  if (!programs && orbitdb) {
    // Load programs database
    programs = await orbitdb.feed('browser.ddb', {
      accessController: { write: [orbitdb.identity.id] },
      create: true
    })
    await programs.load()
  }
  return programs
    ? programs.iterator({ limit: -1 }).collect()
    : []
}

export const getDB = async (address, orbitdb, options) => {
  let db
  if (orbitdb) {
    console.log('Retrieving ',address, 'options', options)
    db = await orbitdb.open(address, options)
    console.log(db)
    await db.load()
    db.events.on('replicated', () => {
      console.log('replicated',db)
      const result = db.iterator({ limit: -1 }).collect().map(e => e.payload.value) // gives an error
      console.log(result.join('\n'))
    })
  }
  return db
}


export function ipldExplorer(address) {
  let url = `https://explore.ipld.io/#/explore/${address}`
  if(url) window.open(url, '_blank').focus();
}

export const addDatabase = async (address, ipfsNode, programs) => {
  const db = await getDagObject(address, ipfsNode)
  console.log('object retrieved:',db)
  return programs.add({
    name: db.name,
    dbAddress:address,
    description:db.description,
    responses: db.responses,
    added: Date.now()
  })

}


export const createDatabase = async (name, type, permissions, provider,extra, ipfsNode, orbitdb, programs) => {
  let accessController
  let options
  switch (permissions) {
    case 'public':
      accessController = { write: ['*'] }
      break
    case 'daoHaus':
      options={ipfs: ipfsNode, web3: provider, contractAddress:extra , accessController:{type:'daohausmember'}} //,defaultAccount: [orbitdb.identity.id]
      break
    case 'orbitdb':
      accessController = {type:'orbitdb', write: [orbitdb.identity.id]}
      break
    default:
      accessController = { write: [orbitdb.identity.id] }
      break
  }

  let db;
  if(options){
    db = await orbitdb.create(name, type, {options})
  }else{
    db = await orbitdb.create(name, type, { accessController })
  }

  programs.add({
    name,
    type,
    dbAddress: db.address.toString(),
    added: Date.now()
  })

  return db.address.toString()
}


export const getPublicKey = async (ipfsNode) =>{
  let ipfsId = await ipfsNode.id();
  return ipfsId.publicKey;
}

const recreateCid = (cid) =>{
  const properCid = new CID(cid);
  return properCid;
}

export const isCID = (cid) => {return CID.isCID(cid)};

export const getDagCid = async (cid, path, ipfsNode) =>{
  if(!CID.isCID(cid)){
    let properCid = recreateCid(cid)
    cid = properCid
  }
  let dataR = (await ipfsNode.dag.get(cid, {path}))
  if(dataR){
      let res = dataR
      console.log(res)
      return res;
    }
    else{
        console.log('error! data is undefined')
      }
}

export const getDagObject = async (cid, ipfsNode) =>{
  // difference v1 and v0.. retrieve both obv..
  for await (const result of ipfsNode.cat(cid.toString())) {
    return result
  }
}

export const getV0 = async (cid, ipfsNode) =>{ //asyncgenerator
  let res = await ipfsNode.get(cid);
  return res;
}

export const getIpfs = async (cid, ipfsNode) =>{
  let res = await ipfsNode.get(cid);
  return res;
}

export const dagPreparation = async (data, ipfsNode) =>{
  // in put {pin:true}
  let cid = await ipfsNode.dag.put(data);
  return cid;
}

export const addIpfs = async (data, ipfsNode) =>{
  let cid = await ipfsNode.add(data);
  console.log(cid)
  return cid.cid;
}

export const getTreeIpfs = async (cid, path, ipfsNode) =>{
  if(!CID.isCID(cid)){
    let properCid = recreateCid(cid)
    cid = properCid
  }
  let results = []
  let tree = await ipfsNode.dag.tree(cid, {path});
  for await (const item of tree) {
    // console.log(item)
    results.push(item)
  }
  return results;
}

export const getFromIpfs = async (cid, ipfsNode) =>{
  const stream = ipfsNode.cat(cid)
  let data = ''
  for await (const chunk of stream) {
    // chunks of data are returned as a Buffer, convert it back to a string // it doesn't work!
    data += chunk.toString()
  }
  if(data !== ''){
    return data;
  }else{
    return 'nothing was found in '+cid.toString()
  }

}
export const removeDatabase = async (hash, programs) => {
  console.log(hash.hash)
  return programs.remove(hash.hash)
}
