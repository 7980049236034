const config = {
  ipfs: {
    // preload: {
    //   enabled: false
    // },
    repo: "ipfs/ddb", // repo will be the location in local ipfs storage
    relay: { enabled: true, hop: { enabled: true, active: true } }, // hop allows it to send and require through ipfs
    EXPERIMENTAL: {
      pubsub: true //required for orbitdb entries
    },
    config: {
      Addresses: {
        Swarm: [ // Search for swarming capabilities (it is possible to create cloud networks )
          // "/ip4/127.0.0.1/tcp/4001",
          // "/ip6/::/etcp/4001",
          // "/ip4/127.0.0.1/tcp/4002/ws",
          '/dns4/wrtc-star1.par.dwebops.pub/tcp/443/wss/p2p-webrtc-star/',
          '/dns4/wrtc-star2.sjc.dwebops.pub/tcp/443/wss/p2p-webrtc-star/',
          '/dns4/webrtc-star.discovery.libp2p.io/tcp/443/wss/p2p-webrtc-star/',
          // '/dns4/ws-star.discovery.libp2p.io/tcp/443/wss/p2p-websocket-star', // not allowed in js-ipfs browser version
        ]
      }
    }
  }
}

export default config
