import React, {useState} from "react";
import {addDatabase} from "../../libs/databaseLib";
import {Button, Input, IconButton, HStack,VStack} from '@chakra-ui/react';
import {Search2Icon} from '@chakra-ui/icons';
import {useSystemsContext} from '../../contexts/IpfsOrbitDb/systems';
function DatabaseImport(props) {
  const [open, setOpen] = useState(false);
  const  [ipfsNode, , , programs] = useSystemsContext();

  async function add(){
    let address = document.getElementById('addressInput').value;
    console.log(address)
    await addDatabase(address, ipfsNode, programs);
    console.log('added!')
    setOpen(false);
  }

  return (
      <VStack>
        <Button variant= 'outline' colorScheme='white' onClick={()=>setOpen(!open)}>Import DB</Button>
        {open?
          <HStack>
            <Input id='addressInput' placeholder='bafy..' w='80%'></Input>
            <IconButton
              icon={<Search2Icon />}
              onClick={()=>add()}>
            Add DB</IconButton>

          </HStack>
          :null}
      </VStack>

  );
}

export default DatabaseImport;
