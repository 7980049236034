import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  VStack,
  Grid,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Route, Switch, Redirect } from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import Header from './components/header';
// import DatabaseCreate from './components/Databases/databaseCreate';
import CreateForm from './components/Databases/create';
import MyDbs from './components/Databases/myDbs';
import Stats from './components/Databases/stats';
import Output from './components/Databases/forms/output';

function App() {
  const history = useHistory();
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
          <Header />
          <Switch>
            <Route path='/create'>
              <CreateForm />
{/*              <DatabaseCreate />*/}
            </Route>
            <Route path='/forms'>
              <MyDbs />
            </Route>
            <Route path='/form/:formCID'>
              <Output
                isCreation={false}
                />
            </Route>
            <Route path='/stats/:formCID'>
              <Stats />
            </Route>
            <Route exact path='/'>
              <Button onClick={()=>history.push('create')}>
                Create DB
              </Button>
              <Button onClick={()=>history.push('vis')}>
                Read CID / Present result
              </Button>
              <Button onClick={()=>history.push('forms')}>Form * Defines struct and open vis tools</Button>
            </Route>
            <Redirect to="/" />
          </Switch>
          </VStack>

        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
