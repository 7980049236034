  import React from 'react';
  import {
    Button,
    Divider,
    VStack,
    Text,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Spinner,
    HStack,
    Input,
    IconButton
  } from "@chakra-ui/react";
  import {useSystemsContext} from '../../contexts/IpfsOrbitDb/systems';
  import {useParams} from 'react-router-dom';
  import {getDB, getDagObject, ipldExplorer} from '../../libs/databaseLib';
  import {CheckIcon, ExternalLinkIcon} from '@chakra-ui/icons';
  import CopyableText from '../commons/copyableText';
  // import CollapsibleTree from '../../D3Graphs/collapsibleTree';

  // const io = require('orbit-db-io')

  export default function Stats(props) {
    const  [ipfsNode, orbit, , myForms,entries] = useSystemsContext();
    const { formCID } = useParams()
    const [loading, setLoading] = React.useState(true);
    // i dont like this.. just create an instance of the db, retrieve data, events and stats!
    const [formObject, setFormObject] = React.useState();
    const [dB, setDB] = React.useState();
    const [formSupported, setFormSupported] = React.useState();
    const [responses, setResponses] = React.useState();

    const addSupport = async()=>{
      let type='eventlog'
      myForms.add({
        name:formObject.name,
        type,
        formData:formObject.formData,
        description: formObject.description,
        responses: formObject.responses,
        added: Date.now()
      })
      console.log('added!')
    }

    const getEntriesKeys = (object) =>{return Object.keys(object)}

    async function giveAccess(address){
      // const address = document.getElementById('accessAddress').value
      let test
      console.log('give access to ',address)
      try{
        test = await dB.access.grant('write', address) // grant access to database2
        console.log(test)
      }catch{
        return 'Error in giving the access'
      }
      if(test){
        console.log('Access granted!')
      }else{
        console.log('Cannot add the address')
      }

    }


    const isSupported = (id) => {
      if(entries && entries.length > 0){
        let entriesIds = entries.map(x=>{return (x.payload.value.responses)})
        return entriesIds.includes(id)
      }
      return false;
    }

    React.useEffect(async ()=>{// eslint-disable-line react-hooks/exhaustive-deps
        if(ipfsNode){ // this gots into trouble
          setLoading(true)

          let cid = formCID.split('/form/')
          // get the form associated with that cid
          let formObj = await getDagObject(cid, ipfsNode)
          console.log(formObj)
          if(formObj){
            setFormObject(formObj)
            setFormSupported(isSupported(formObj.responses))
            // get an instance of the database inside the formObject
            let db = await getDB(formObj.responses,orbit) //, {web3:web3Provider, contractAddress:}
            setDB(db)
            // if(db){
            //   let dbManifest = await io.read(ipfsNode, db.address.root)
            //   const hash = dbManifest.accessController.split('/').pop()
              // let acManifest = await io.read(ipfsNode, hash)
              // let access = await io.read(ipfsNode, acManifest.params.address)
              // console.log('manifest',acManifest)
              // console.log('access',access)
            // }
            // and query its contents
            let resp
            if(db && db.type === 'eventlog'){
              resp = await db.iterator({ limit: 100 }).collect().reverse()
              setResponses([...resp])
            }else if(db && db.type==='keyvalue'){
              resp = await db.all
              let respKeys = getEntriesKeys(resp)
              console.log('flattened',respKeys)
              let flattened = respKeys.reduce((total, currentValue) => total.concat({key:currentValue, value:resp[currentValue]}), []);
              setResponses(flattened)
            }else if(db){ // feed, docstore and counter (or customs)
                resp = await db.all
                setResponses(resp)
            }else{
              setResponses()
            }

          }
          setLoading(false)
        }
    },[formCID, ipfsNode, orbit, myForms])// eslint-disable-line react-hooks/exhaustive-deps

    const returnDate = (timestamp) =>{return new Date( timestamp* 1000)}


    const resultsTable = () => {
      // let type;


      switch (dB._type) {
        case 'keyvalue':
        return (
          <Tbody>
          {responses.map((x)=>{return(
              <Tr key={x.key.concat('line')}>
                <Td key={x.key}>{x.key}</Td>
                {formObject.formData.map(y=>{return(
                    <Td key={x.key.concat('results')}>{x.value.value[y.name].toString()}</Td>
                  )}
                )}
              </Tr>
            )})}
          </Tbody>
        )
        case 'docstore':
        return (
          <Tbody>
          {responses.map((x)=>{return(
              <Tr key={x.payload.key.concat('line')}>
                <Td key={x.payload.key}>{x.payload.key}</Td>
                {formObject.formData.map(y=>{return(
                    <Td key={x.payload.key.concat('val')}>{x.payload.value.value[y.name].toString()}</Td>
                  )}
                )}
              </Tr>
            )})}
          </Tbody>
        )
        default:
        return(
          <Tbody>
          <Tr><Td key='def'>default</Td></Tr>
          </Tbody>
        )
      }}



    function shorten(text){
      let newText
      if(Array.isArray(text)){
        newText =  text[0].slice(0,5).concat('..')
      }else{
        newText = text.slice(0,5).concat('..')
      }
      return newText
    }
    return (
      <Stack>
        {loading?
          <VStack>
            <Spinner />
            <Text>Retrieving the db & responses</Text>
          </VStack>
          :
            <VStack>

            {dB?
              <VStack>
                  <CopyableText text= {formCID.split('/form/')} />
                  <HStack>
                  <VStack>
                    <HStack>
                      <Text>Name: {formObject.name}</Text>
                      <IconButton
                        onClick={()=>ipldExplorer(formCID.split('/form/'))}
                        icon={<ExternalLinkIcon />}
                        />
                    </HStack>
                    <Text>Description: {formObject.description?formObject.description:null}</Text>
                    {formSupported?
                      <HStack>
                        <Text>Form supported </Text>
                        <CheckIcon />
                      </HStack>
                    :
                      <Button onClick={()=>{addSupport()}}>Support this form!</Button>
                    }
                    <Text>Type: {dB._type}</Text>
                    <Text>Access: {dB?.access?._write? shorten(dB.access._write):'unknown'}</Text>
                    <Text>Created: {returnDate(dB.created).toString()}</Text>
                  </VStack>
                  <VStack>
                    <Text>Status of DB</Text>
                    <Text>Replications: {dB._replicationStatus.max}</Text>
                    <Text>Events (entries): {dB.events._eventsCount}</Text>
                  </VStack>
                  </HStack>
                  <Divider />
                </VStack>
                :'loading DB'}
                {/*
                  add access to the manifest: dB.manifestPath

                  */}
              <HStack>
                {responses?
                  <Text>Responses: {responses.length}</Text>
                  :null}
                <Button isDisabled onClick={()=>console.log('export to csv!')}>Download responses</Button>
              </HStack>
              <Divider/>
              <Table>
              <Thead style={{backgroundColor:'gray'}}>
                <Tr key='head'>
                  <Td key='h'>Key</Td>
                  {formObject.formData.map(x=>{return(
                    <Td key={x.name}>{x.name}</Td>
                  )})}
                </Tr>
              </Thead>
              {responses && responses.length > 0 ?
                resultsTable()
                :'There are no entries yet'}
              </Table>
  {/*
              <CollapsibleTree
              data = {formObject.formData}
              // dataGraphed = {dataGraphed}
              />
  */}
              <Divider />
              <Input id='orbitDbId' placeholder='orbitdb Public Id?'></Input>
              <Button onClick={()=>giveAccess(document.getElementById('orbitDbId').value)}>Give access</Button>
          </VStack>
        }
      </Stack>
    );
  }
