import {
  Text,
  VStack,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  TableCaption,
  HStack,
} from '@chakra-ui/react';
import {DeleteIcon, EditIcon, InfoIcon } from '@chakra-ui/icons';
import {useSystemsContext} from '../../contexts/IpfsOrbitDb/systems';
import {useHistory} from 'react-router-dom';

function MyDbs() {
  const  [, , , myForms, entries] = useSystemsContext();
  const history = useHistory();

  const removeDatabase = async (hash) => {
    if(window.confirm("Do you really want to unsupport this form?")){
      console.log('deleted!')
      return myForms.remove(hash.hash)
    }else{
      console.log('Rejected by the user')
    }
    // react to the entries change.. (refresh?)
  }


  return (
    <VStack>
      <Divider />
      {entries && entries.length > 0?
        <Table>
          <TableCaption placement='top'>My forms supported!</TableCaption>
          <Divider />
          <Thead>
            <Tr key='title'>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Type</Th>
              <Th>Functions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {entries.map(x=>{return(
              <Tr key={x.hash}>
              <Td >{x.payload.value.name}</Td>
              <Td >{x.payload.value.description}</Td>
              <Td >{x.payload.value.type}</Td>
{/*
  <CopyableText text={'localhost:3000/#/form/'+x.payload.value.formDataCid} />
                */}
                <Td >
                <HStack>
                  <IconButton
                    onClick={()=>{history.push('form/'+x.payload.value.dbAddress)}}
                    aria-label="Edit"
                    icon={<EditIcon />} />
                  <IconButton
                    onClick={()=>{history.push('stats/'+x.payload.value.dbAddress)}}
                    aria-label="Stats"
                    icon={<InfoIcon />} />
                    {/*
*/}
                  <IconButton
                    onClick={()=>removeDatabase(x)}
                    aria-label="Delete"
                    icon={<DeleteIcon />} />
                </HStack>
                </Td>
              </Tr>
            )})}
          </Tbody>

          </Table>
        :
        <Text >You have no forms!</Text>
      }
    </VStack>
  );
}

export default MyDbs;
