import React from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Radio,
  RadioGroup,
  Divider,
  VStack,
  Checkbox,
  Text,
  FormHelperText,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  HStack,
  Slider,
  SliderTrack,
  Box,
  SliderThumb,
  SliderFilledTrack,
  Stack,
  Spinner
} from "@chakra-ui/react";
import {useSystemsContext} from '../../../contexts/IpfsOrbitDb/systems';
import {useWeb3Context} from '../../../contexts/Web3Context';
import {useParams} from 'react-router-dom';
import {getDB, getDagObject} from '../../../libs/databaseLib';
import {useHistory} from 'react-router-dom';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css"; // needed!!!
// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default function Output(props) {
  const  [ipfsNode, orbit, , myForms] = useSystemsContext();
  const {account} = useWeb3Context();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { formCID } = useParams()
  const [formObject, setFormObject] = React.useState();
  const [formCid, setFormCid] = React.useState();
  const [dB, setDB] = React.useState();
  const history = useHistory();
  const includeWeb3Account = false;

  const getPublicKey = async () =>{
    let ipfsId = await ipfsNode.id();
    return ipfsId.publicKey;
  }

  const addSupport = async()=>{
    let type='eventlog'
    myForms.add({
      name:formObject.name,
      type,
      formData:formObject.formData,
      description: formObject.description,
      responses: formObject.responses,
      added: Date.now()
    })
    console.log('added!')
  }


  React.useEffect(async ()=>{// eslint-disable-line react-hooks/exhaustive-deps
    let formData
      if(!props.isCreation && ipfsNode){        // this gots into trouble

        let cid = formCID.split('/form/')
        console.log(cid)
        setFormCid(cid)

        let formObj = await getDagObject(cid, ipfsNode)
        if(formObj){
          setFormObject(formObj)
          formData = formObj.formData
          let db = await getDB(formObj.responses, orbit)
          setDB(db)
        }
      }else{
        setFormObject({formData:props.formData});
      }
      console.log('formData:',formData)
    },[formCID, ipfsNode, props.isCreation, orbit, props.formData]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit =async (data) => {
    // HANDLE DIFFERENT TYPES!!!
    let k
    switch(formObject.keyDefined){
      case 'web3':
        k = account
      break
      case 'orbitdb':
        k = await getPublicKey()
      break
      case 'free':
        k = document.getElementById('keyFree').value// some kind of input
      break
      default:
        k = getPublicKey()
    }
    let done = await createEntry(k, data)
    console.log(done)
    if(done !== false){
      window.alert('Entry sent!')
      history.push('/stats/'+formCid)
    }else{
      window.alert('Error')
    }
  };
  const onError = error =>{
    console.log(error);
  }


  async function createEntry(key, value){
    // if (event) event.preventDefault()
    // if (value.length === 0) return
    let db = dB;
    let res;
    if (db.type === 'eventlog') {
        //  Metadata of the log
      let timestamp = new Date();
      let obj = {value:value,  timestamp:timestamp}
      // if(includeWeb3Account){
      //   obj = {value:value,  timestamp:timestamp, account:account}
      // }else{
      //   obj = {value:value,  timestamp:timestamp}
      // }
      res = await db.add({key:key, obj})
    }else if(db.type === 'keyvalue'){
      if(includeWeb3Account){
        res = await db.put(key, {value:value, account:account})
      }else{
        res = await db.put(key,{value:value})
      }
    }else if(db.type === 'docstore'){
      res = await db.put({_id:key, value:value});
    }else if(db.type === 'counter'){
      let fl
      try{
        fl = parseFloat(value)
      }catch{
        console.log('Please insert a number!')
        return
      }
      res = await db.inc(fl);
    }
    else{
      throw new Error('There was an error!')
    }
    if(res){
      console.log('Saved!')
    }else{
      window.alert('there was an error!')
    }
  }


  // let options;
const formElement = (item) => {
  // let type;
  switch (item.type) {
    case 'text':
    return (
      <FormControl isInvalid={errors.name} isRequired={item.required} key={item.name}>
        <FormLabel htmlFor="name">{item.name}</FormLabel>
        <Input
          id={item.name}
          placeholder={item.name}
          {...register(`${item.name}`, {
            required: item.required,
            maxLength:item.maxLength ,
            max:item.max,
            min:item.min,
            pattern:item.pattern
          })}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
    )
    case 'textarea':
    return (
      <FormControl isInvalid={errors.name} isRequired={item.required} key={item.name}>
        <FormLabel htmlFor="name">{item.name}</FormLabel>
        <Input
          id={item.name}
          placeholder={item.name}
          {...register(`${item.name}`, {
            required: item.required?true:false,
            maxLength:item.maxLength?item.maxLength:128 ,
            pattern:item.pattern?item.pattern:null
          })}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
    )
    case 'select':
    let optionsSelect = item.options.split(';');
    return (
      <FormControl isRequired={item.required}  key={item.name}>
        <FormLabel>{item.name}</FormLabel>
        <Select placeholder={item.name}
        {...register(`${item.name}`, {
          required: item.required,
          pattern:item.pattern
        })}>
          {optionsSelect.map(x=>{return(
            <option key={x} value={x}>{x}</option>
          )})}
        </Select>
      </FormControl>
    );
    case 'checkbox':
    return (
      <FormControl isRequired={item.required}>
        <FormLabel>{item.name }</FormLabel>
        <Checkbox
        {...register(`${item.name}`, {
          required: item.required,
          pattern:item.pattern
        })}
         />
      </FormControl>
    );
    case 'radio':
    let optionsRadio = item.options.split(';');
    return (
      <FormControl isRequired={item.required} key={item.name}>
        <FormLabel>{item.name}</FormLabel>
        <RadioGroup>
        <Stack>
        {optionsRadio.map((x)=>{return(
          <Radio key={x} value={x} {...register(`${item.name}`,{required:true})}>{x}</Radio>
        )})}
        </Stack>

        </RadioGroup>
      </FormControl>
    );
    case 'number':
    return (
      <FormControl id={item.name} isRequired={item.required} key={item.name}>
        <FormLabel>{item.name}</FormLabel>
        <NumberInput max={item.max?item.max:100} min={item.min?item.min:0}> {/*breaks on register*/}
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>    );

    case 'range':
    let maximum = item.max? item.max : 100
    let minimum = item.min? item.min : 0
    return (
      <FormControl id={item.name} isRequired={item.required}  key={item.name}>
        <FormLabel>{item.name}</FormLabel>
        <Slider min={minimum} max={maximum} onChangeEnd={(value) => console.log(value)}>
          <SliderTrack bg="red.100">
            <Box position="relative" right={10} />
            <SliderFilledTrack bg="tomato" />
          </SliderTrack>
          <SliderThumb boxSize={6}>{item.name.value}</SliderThumb>
        </Slider>

        <Text></Text>
      </FormControl>
    );
    case 'email':
    return (
      <FormControl id={item.name} isRequired={item.required} key={item.name}>
        <FormLabel>Email address</FormLabel>
        <Input type="email" {...register(`${item.name}`, {
          required: item.required,
          maxLength:item.maxLength ,
          max:item.max,
          min:item.min,
          pattern:item.pattern
        })} />
        <FormHelperText>dont enter your email. This is a public and open database!</FormHelperText>
      </FormControl>
    );
    case 'datetime':
    // {/*showTimeSelect dateFormat="Pp"*/}
    // {/*Datetime pickers need to be referred to its item.name also for later*/}
    return (
      <FormControl id={item.name} isRequired={item.required} key={item.name}>
        <FormLabel>{item.name}</FormLabel>
        <DatePicker
          placeholderText='Select date'
          onChange={(date) => {
            let inputElement = document.getElementById(item.name.concat('_selected'))
            inputElement.value = date.toISOString() //actually should be timestamp.. then frontend converts to local

            // inputElement.addEventListener('change', function(ev){
            //   console.log(ev)
            // }).then(()=>{
            //
            //   inputElement.value = date.toISOString() //actually should be timestamp.. then frontend converts to local
            // })
          }}
          />
        <Input id={item.name.concat('_selected')} onChange={(data)=>console.log(data)}
          {...register(`${item.name}`, {
          required: item.required
        })}></Input>
      </FormControl>

     );

    default:
    return null;
  }
  };

  return (
    <Stack>
      {!formObject?
        <VStack>
          <Spinner />
          <Text>Retrieving the form..</Text>
        </VStack>
        :
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <VStack>
            <VStack>
              <Text  fontSize='md'>{formObject.name?formObject.name:props.formName}</Text>
              <Text  fontSize='sm'>{formObject.description?formObject.description:props.formDescription}</Text>
            </VStack>

{/*for free keys, expose an input*/}
            {formObject.keyDefined === 'free'?
              <Input id='keyFree' placeholder='key'></Input>
            :null}

{/*then reproduce the form*/}
            {formObject.formData && formObject.formData.length > 0?
            <VStack>
                {formObject.formData.map(x=>{return(
                  formElement(x)
                )})}
              <Divider />
            </VStack>
          :'error!!'}
{/*if isCreation (only visualizer) avoid submit functions*/}
          {!props.isCreation && dB?
            <HStack>
              <Button onClick={()=>addSupport()}>Support this form!</Button>
              {formObject?
                <Button isDisabled={!formObject.responses} type='submit'>Send!</Button>
                :null}
            </HStack>
            :null}
            {/*      await db.add({key:key,value:ipfsCid.string})*/}
            </VStack>
        </form>
      }
    </Stack>
  );
}
